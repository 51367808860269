@import './assets/theme/deeporange/theme-dark';
$dividerColor: #bdbdbd;
$dividerLightColor: #cacaca;
$primaryDarkColor: #BF360C;

$darkColor: #BF360C;
$lightColor: #D84315;

/* styles-ultima.scss */
body {
    .dashboard {
        .colorbox {
            i {
                font-size: 50px;
            }
        }
    }

    .pages-body {
        .topbar {
            background-color: $darkColor;
        }

        &.login-page {
            background: unset;

            .pages-panel {
                min-width: 350px;

                .pages-header {
                    background: $darkColor;

                    h2 {
                        color: #ffffff;
                    }
                }

                .input-panel {
                    p-dropdown, .p-dropdown {
                        width: 100%;
                    }
                }

                &.card {
                    img {
                        &.login-image {
                            width: 200px;
                            height: 200px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }

    .p-selectbutton, .p-togglebutton {
            &.p-button {
                background: $darkColor;
                color: #ffffff;

                &:hover {
                    background: $lightColor;
                }

                &.p-highlight {
                    background: $darkColor;

                    span {
                        &.p-button-icon {
                            color: #ffffff;
                        }
                    }
                }
            }
        }

    .card h1 {
        font-size: 1.5em;
        font-weight: 400;
        margin: 1em 0;
    }

    .p-panel {
        .p-panel-title {
            color: $primaryDarkColor;
        }
    }

    .p-datatable {
        .p-datatable-thead {
            > tr {
                border-color: $dividerColor;

                th {
                    background-color: $primaryDarkColor;
                    color: #ffffff;
                    text-align: center; //Became left in v8.0.0
                    //border: 1px solid $dividerLightColor; //Became invisible in v8.0.0
                }
            }
        }

        .p-datatable-tbody {
            > tr {
                > th {
                    border: 1px solid $dividerLightColor;
                }

                > td {
                    //border: 1px solid $dividerLightColor; //Became invisible in v8.0.0
                }
            }
        }

        tr.p-widget-header.p-rowgroup-header {
            background-color: $darkColor;
            color: #ffffff;
            font-weight: 500;
        }
    }

    .login-panel {
        width: 400px;

        .p-dropdown {
            text-align: left;
        }
    }

    a {
        &, &:visited, &:active, &:hover {
            color: $lightColor;
        }
    }

    .p-tabview-left > .p-tabview-nav {
        width: 15%;
        height: 500px;
    }

    .p-tabview-left > .p-tabview-panels {
        width: 85%;
    }
}
