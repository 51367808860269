@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import './styles-ultima';

$tableOddRowBgColor: #f4f4f4;
$tableBodyRowBgColor: #ffffff;

/* styles.scss */
body {
    .p-inputtext[readonly] {
        background-image: none;
    }

    textarea {
        width: 100%;
        font-family: inherit;
    }

    div {
        &.footer {
            margin-top: 30px;
        }
    }

    .width-full {
        min-width: 100%;
        width: 100%;
    }

    @media screen and (min-width: 64.063em) {
        .width1 {
            min-width: 75px;
            width: 75px;
        }

        .width2 {
            min-width: 100px;
            width: 100px;
        }

        .width3 {
            min-width: 125px;
            width: 125px;
        }

        .width4 {
            min-width: 150px;
            width: 150px;
        }

        .width5 {
            min-width: 175px;
            width: 175px;
        }

        .width6 {
            min-width: 200px;
            width: 200px;
        }

        .width7 {
            min-width: 225px;
            width: 225px;
        }

        .width8 {
            min-width: 250px;
            width: 250px;
        }

        .width9 {
            min-width: 275px;
            width: 275px;
        }

        .width10 {
            min-width: 300px;
            width: 300px;
        }

        .width11 {
            min-width: 325px;
            width: 325px;
        }

        .width12 {
            min-width: 350px;
            width: 350px;
        }

        .width13 {
            min-width: 375px;
            width: 375px;
        }

        .width14 {
            min-width: 400px;
            width: 400px;
        }

        .width15 {
            min-width: 425px;
            width: 425px;
        }

        .width16 {
            min-width: 450px;
            width: 450px;
        }

        .width17 {
            min-width: 475px;
            width: 475px;
        }

        .width18 {
            min-width: 500px;
            width: 500px;
        }

        .width19 {
            min-width: 525px;
            width: 525px;
        }

        .width20 {
            min-width: 550px;
            width: 550px;
        }

        .left {
            text-align: left !important;
        }

        .center {
            text-align: center !important;
        }

        .right {
            text-align: right !important;
        }

        .return-col {
            min-width: 85px;
            width: 85px;
        }
    }

    .clickable {
        cursor: pointer;
    }

    .with-bottom-margin {
        margin-bottom: 20px;
    }

    .with-right-margin {
        margin-right: 20px;
    }

    .form-group .p-col-12 {
        padding: 16px 10px;
    }

    table {
        border-collapse: collapse;

        thead.p-datatable-thead, tbody.p-datatable-tbody, tfoot.p-datatable-tfoot {
            tr {
                //Ultima uses tr:nth-child(odd) to do table striping but we need to be able to do it manually sometimes.
                &.p-datatable-even {
                    background: $tableBodyRowBgColor;
                }

                &.p-datatable-odd {
                    background-color: $tableOddRowBgColor;
                }

                th {
                    padding: 8px;

                    i {
                        &.help-icon {
                            color: #ffffff;
                        }
                    }

                    &.border-left {
                        border-left: 1px solid #bdbdbd;
                    }
                }

                th, td {
                    text-align: center;

                    &.reorder-column {
                        width: 45px;
                    }

                    &.transparent {
                        background: transparent !important;
                        border: none !important;
                    }

                    input {
                        width: 100%;
                    }
                }

                &.zero-balance-holding {
                    td {
                        font-style: italic;
                        color: #757575;
                    }
                }
            }
        }

        tfoot.p-datatable-tfoot > tr > td .p-column-title {
            display: none;
        }

        @media screen and (max-width: 40em) {
            .p-datatable-responsive .p-datatable-tfoot > tr > td .p-column-title {
                padding: .4em;
                min-width: 30%;
                display: inline-block;
                margin: -.4em 1em -.4em -.4em;
                font-weight: bold;
            }
        }
    }

    @media screen and (max-width: 65em) {
        table {
            &.p-datatable-responsive-md .p-datatable-thead > tr > th,
            &.p-datatable-responsive-md .p-datatable-tfoot > tr > td,
            &.p-datatable-responsive-md .p-rowgroup-header {
                display: none !important;
            }

            &.p-datatable-responsive-md colgroup {
                display: none !important;
            }

            &.p-datatable-responsive-md .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                border: 0 none;
                width: 100% !important;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                float: left;
                clear: left;

                .non-responsive-only {
                    display: none;
                }
            }

            &.p-datatable-responsive-md .p-datatable-tbody > tr > td .p-column-title {
                padding: .4em;
                min-width: 30%;
                display: inline-block;
                margin: -.4em 1em -.4em -.4em;
                font-weight: bold;
            }

            &.p-datatable-responsive-md .p-datatable-tfoot > tr > td .p-column-title {
                padding: .4em;
                min-width: 30%;
                display: inline-block;
                margin: -.4em 1em -.4em -.4em;
                font-weight: bold;
            }
        }
    }

    .fa-rotate-45 {
        -ms-transform: rotate(45deg); /* Internet Explorer 9 */
        -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
        transform: rotate(45deg); /* Standard syntax */
    }

    .fa-rotate-135 {
        -ms-transform: rotate(135deg); /* Internet Explorer 9 */
        -webkit-transform: rotate(135deg); /* Chrome, Safari, Opera */
        transform: rotate(135deg); /* Standard syntax */
    }

    .fa-rotate-225 {
        -ms-transform: rotate(225deg); /* Internet Explorer 9 */
        -webkit-transform: rotate(225deg); /* Chrome, Safari, Opera */
        transform: rotate(225deg); /* Standard syntax */
    }

    .fa-rotate-315 {
        -ms-transform: rotate(315deg); /* Internet Explorer 9 */
        -webkit-transform: rotate(315deg); /* Chrome, Safari, Opera */
        transform: rotate(315deg); /* Standard syntax */
    }


    a {
        &:visited, &:hover, &:active {
            color: $lightColor;
        }

        &:hover {
            text-decoration: underline;
        }

        &.p-tabview-nav-link {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .icons {
        i {
            margin-top: 5px;
            margin-right: 12px;
        }

        &.right {
            float: right;
        }

        @media screen and (max-width: 65em) {
            i {
                margin-top: 20px;
                margin-right: 25px;
                font-size: 20px;
            }

            &.right {
                float: none;
            }
        }
    }

    .positive-return {
        color: #34A835 !important;
        font-weight: bold !important;
    }

    .negative-return {
        color: #e91224 !important;
        font-weight: bold !important;;
    }

    .status-red {
        color: #e91224;
        font-weight: bold;
    }

    .status-amber1 {
        color: #ff9800;
        font-weight: bold;
    }

    .status-amber2 {
        color: #ef6c00;
        font-weight: bold;
    }

    .status-green {
        color: #34A835;
        font-weight: bold;
    }

    .status-icons {
        i {
            margin-top: 5px;
            margin-right: 12px;
        }
    }

    .add-icon {
        color: #34A835;
    }

    .remove-icon {
        color: #e91224;
    }

    .delete-icon {
        color: #ff1744;
    }

    .help-icon {
        color: #0277bd;
    }

    .logo {
        color: #ffffff;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 28pt;
    }
}
